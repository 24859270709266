body{
	background-color: #e2eef4;
}

.well:hover{
	box-shadow:  0px 0px 4px #ddc;
	-webkit-box-shadow: 0px 0px 4px #ddc;
	-moz-box-shadow:  0px 0px 4px #ddc;
}

.jumbotron{
	background-color: lightblue;
	box-shadow:  0px 0px 4px #ddc;
	-webkit-box-shadow: 0px 0px 4px #ddc;
	-moz-box-shadow:  0px 0px 4px #ddc;
}

.navbar{
	border-radius:0px;
}

.navbar-inverse .navbar-brand{
	font-weight:bold;
	color:violet;
}

.brandname{
	font-weight:bold;
	color:violet;
}
